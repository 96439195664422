<template>
  <div class="py-4">
    <b-row>
      <b-col cols="4">
        <basic-input
          :value.sync="filterCustomer"
          :debounce="500"
          name="searchText"
          label="Tên khách hàng"
          placeholder="Nhập tên khách hàng"
          @v-modal:update="onSearchCustomer"
        ></basic-input>
      </b-col>
    </b-row>

    <template-table
      :column="column"
      :data="items"
      :paging="paging"
      :tableAction="false"
      :selectAction="false"
      :searchAction="false"
      @sortBy="handleSort"
    >
      <template v-slot:body="{ item }">
        <td>
          <div class="d-flex align-items-center">
            <avatar
              :text="item.fullName"
              :src="item.image && item.image.url"
              :rounded="true"
              size="40px"
            />
            <div class="d-flex flex-column ml-5">
              <p class="mb-0">
                <a
                  v-if="item.isViewProfile"
                  class="a-link cursor-pointer"
                  @click="goToUserDashboard(item)"
                >
                  {{ item.fullName }}
                </a>
                <span v-else>{{ item.fullName }}</span>
              </p>
              <p class="mb-0">
                {{ item.packageName || 'Gói free' }}
              </p>
            </div>
          </div>
        </td>
        <td style="width: 20px">
          <action-dropdown
            :value="item"
            :show_copy="false"
            :show_view="false"
            :show_edit="false"
            :show_delete="false"
            :boundary="items.length > 2 ? 'scrollParent' : 'window'"
          >
            <template>
              <b-dropdown-text
                v-if="item.isViewProfile"
                tag="div"
                class="navi-item cursor-pointer"
                @click="onClickAction(item, 'UserDashboard')"
              >
                <a class="navi-link text-primary">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <b-icon-grid class="mr-2" scale="1.2" />
                  </span>
                  <span class="navi-text text-primary">Xem dashboard user</span>
                </a>
              </b-dropdown-text>
              <b-dropdown-divider
                v-if="item.isViewProfile"
              ></b-dropdown-divider>
              <b-dropdown-text
                tag="div"
                class="navi-item cursor-pointer"
                @click="onClickAction(item, 'UserApp')"
              >
                <a class="navi-link text-primary">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/user-info.svg"
                    />
                  </span>
                  <span class="navi-text text-primary">
                    Xem chi tiết user app
                  </span>
                </a>
              </b-dropdown-text>
            </template>
          </action-dropdown>
        </td>
        <td>{{ formatDate(item.activedDate) }}</td>
        <td>{{ formatDate(item.expiredDate) }}</td>
        <td>
          <span v-if="item.comment" v-html="item.comment"></span>
        </td>
        <td>
          <span
            v-if="item.finalAssessment"
            v-html="item.finalAssessment.comment"
          ></span>
        </td>
      </template>
    </template-table>
  </div>
</template>

<script>
import { tableMixin } from './../mixins/TableFinished';
import { commonTableMixin } from './../mixins/CommonTable';

export default {
  name: 'TableFinished',

  mixins: [tableMixin, commonTableMixin],

  data() {
    return {
      // Some data from tableMixin and commonTableMixin
    };
  },

  methods: {
    // Some functions from tableMixin and commonTableMixin
  },
};
</script>

<style lang="scss" scoped>
.underline,
.underline:hover {
  text-decoration: underline !important;
}
</style>
